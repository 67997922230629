<template>
  <div class="cards">
    <div class="card">
      <div class="cardContainer">
        <div class="textCard">
          <h2>
            <i class="fa fa-briefcase"> </i>
            {{ $t('dashboard.manufacturers') }}
          </h2>
          <label class="num">{{ manufacturersCount }}</label>
        </div>
        <div class="textCard">
          <h2>
            <i class="fa fa-qrcode"></i>
            {{ $t('dashboard.items') }}
          </h2>
          <label class="num">{{ itemsCount }}</label>
        </div>
      </div>
    </div>
    <div class="diagram">
      <label class="pieLabel">{{ $t('dashboard.ticketStatus') }}</label>
      <TicketTypesCircleChart/>
    </div>
    <div class="pieDiv">
      <label class="pieLabel">{{ $t('dashboard.byUser') }}</label>
      <TicketByUsersCircleChart/>
    </div>
  </div>
</template>


<script>
import Link from "../utils/Link";
import axios from "axios";
import TicketTypesCircleChart from "./TicketTypesBarChart";
import TicketByUsersCircleChart from "./TicketByUsersCircleChart";
import Chart from 'chart.js'
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels'
import Vue from "vue";
import VueToast from "vue-toast-notification";

Chart.plugins.register(ChartJsPluginDataLabels);
Chart.defaults.global.plugins.datalabels.font.size = 40;
Chart.defaults.global.plugins.datalabels.font.family = "BloggerSans";

export default {
  components: {TicketByUsersCircleChart, TicketTypesCircleChart},
  mounted() {
    this.$router.history
    this.getItemsCount();
    this.getManufacturersCount()
    this.$emit("changeComponent", "dashboard");
  },
  data() {
    return {
      manufacturersCount: 0,
      itemsCount: 0,
    }
  },
  methods: {
    getManufacturersCount() {
      const headers = Link.methods.getHeaders();
      return axios.get(
          Link.methods.getManufacturesUrl(),
          {headers}
      ).then(res => {
        this.manufacturersCount = res.data.length;
      }).catch(() =>
          this.createToast(this.$t("dashboard.itemsNotFound"), "error")
      );
    },

    getItemsCount() {
      const headers = Link.methods.getHeaders();
      return axios.get(
          Link.methods.getItemsAllUrl(),
          {headers}
      ).then(res => {
        return this.itemsCount = res.data.length;
      }).catch(() =>
          this.createToast(this.$t("dashboard.manufacturerNotFound"), "error")
      );
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 5000
      });
    }
  }
}
</script>


<style scoped>
.num {
  font-size: 150px;
}

.card {
  margin-left: 2%;
  border-color: black;
  transition: 0.3s;
  border-radius: 5px;
  width: max-content;
  text-align: center;
  height: max-content;
  /*background-color: #E9E9E9;*/
}

.cards {
  display: flex;
  flex-wrap: wrap;
  height: 80vh;
  justify-content: center;
  margin: 0;
}


.textCard {
  margin-bottom: 10%;
}

.cardContainer {
  width: max-content;
}

.pieLabel {
  font-size: 32px;
}

.diagram {
  padding-top: 30px;
  padding-left: 30px;
  text-align: center;
}

.pieDiv {
  text-align: center;
  padding: 30px;
}
</style>