<script>
import Link from "../utils/Link";
import {Bar} from "vue-chartjs";
import axios from "axios";
import Vue from "vue";
import VueToast from "vue-toast-notification";

export default {
  extends: Bar,
  mounted() {
    this.getTicketsStatuses();
  },
  data() {
    return {
      statuses: [],
      statusesNames: [],
      statusesCounts: [],
      noneStatuses: 0,
      newStatuses: 0,
      inProgressStatuses: 0,
      closedStatuses: 0,
      rejectedStatuses: 0,
      statusLength: 0
    }
  },
  methods: {
    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 5000
      });
    },
    getTicketsStatuses() {
      const headers = Link.methods.getHeaders();
      axios.get(Link.methods.getTicketSettingStatusesUrl(), {headers}).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          let statusObj = {
            name: res.data[i].name,
            count: 0
          }
          this.statusesNames.push(res.data[i].name)
          this.statuses.push(statusObj)
        }
        this.statusLength = this.statuses.length;
      }).then(() => {
        this.createDiagram();
      }).catch(() => {
        this.createToast(this.$t('failWithLoadStatuses'), "error")
      });
    },
    createDiagram() {
      const headers = Link.methods.getHeaders();
      axios.get(Link.methods.getTicketsUrl(), {headers}).then(res => {
        for (let i = 0; i < this.statuses.length; i++) {
          for (let j = 0; j < res.data.length; j++) {
            if (res.data[j].ticketStatus === this.statuses[i].name) {
              this.statuses[i].count++;
            }
            this.statusesCounts[i] = this.statuses[i].count;
          }
        }
      }).then(() => {
        let canvas = this.$refs.canvas;
        if (canvas == null) {
          return;
        }

        this.gradient = canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 450);

        this.gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");

        this.renderChart(
            {
              labels: this.statusesNames,
              datasets: [
                {
                  datalabels: {
                    color: 'Black',
                    formatter: function (value) {
                      if ((value / 1000) >= 1) {
                        return value / 1000 + "k"
                      } else return value;
                    },
                    display: function (context) {
                      return context.chart.data.datasets[0].data[context.dataIndex] !== 0;
                    }
                  },
                  label: this.$t('dashboard.ticketStatus'),
                  backgroundColor: this.gradient,
                  data: this.statusesCounts
                }
              ]
            },
            {
              tooltips: {
                footerFontFamily: "BloggerSans",
                bodyFontFamily: "BloggerSans",
                titleFontFamily: "BloggerSans",
              },
              legend: {
                display: false,
                labels: {
                  fontFamily: "BloggerSans"
                }
              },
              scales: {
                xAxes: [{
                  ticks: {
                    fontFamily: "BloggerSans",
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90
                  }
                }],
                yAxes: [{
                  ticks: {
                    fontFamily: "BloggerSans"
                  }
                }]
              }
            }
        );
      })
    }
  }
}
</script>

<style scoped>

</style>