<script>
import Link from "../utils/Link";
import {Pie} from "vue-chartjs";
import axios from "axios";
import App from "../../App";


export default {
  extends: Pie,
  mounted() {
    this.getUserCount()
  },
  data() {
    return {
      byUser: [],
      others: {},
      usersLabels: [],
      usersTickets: []
    }
  },
  methods: {
    fill() {
      for (let i = 0; i < this.byUser.length && i < 3; i++) {
        this.usersLabels.push(this.byUser[i].name)
        this.usersTickets.push(this.byUser[i].userTickets)
      }
    },
    getUserCount() {
      const headers = Link.methods.getHeaders();
      new Promise((resolve) => {
        axios.get(Link.methods.getTicketsUrl(), {headers}).then(res => {
          for (let i = 0; i < res.data.length; i++) {
            let user = res.data[i].assigned;
            let userTickets = 0;
            for (let j = 0; j < res.data.length; j++) {
              if (res.data[j].assigned === user) {
                userTickets++;
              }
            }
            let newUser = {
              name: user,
              userTickets: userTickets
            }
            if (!this.byUser.some(user => user.name === newUser.name)) {
              this.byUser.push(newUser);
            }
          }

          this.byUser.sort(App.methods.comparator(a => a.userTickets, false));

          if (this.byUser.length <= 3) {
            this.fill();
          } else {
            this.fill();
            let others = {
              name: this.$t("dashboard.others"),
              userTickets: 0
            }
            for (let i = 3; i < this.byUser.length; i++) {
              others.userTickets += this.byUser[i].userTickets;
            }
            this.others = others;

            this.usersLabels.push(others.name)
            this.usersTickets.push(others.userTickets)
          }
          resolve();
        });
      }).then(() => {
        let canvas = this.$refs.canvas;
        if (canvas == null) {
          return
        }

        this.gradient = canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 450);
        this.gradient2 = canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 450);
        this.gradient3 = canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 450);
        this.gradient4 = canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 450);

        this.gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");

        this.gradient2.addColorStop(0, "rgba(0, 231, 255, 0.9)");

        this.gradient3.addColorStop(0, "rgba(0, 255, 0, 0.9)");

        this.gradient4.addColorStop(0, "rgba(150, 150, 150, 0.9)");

        this.renderChart(
          {
            labels: this.usersLabels,
            datasets: [
              {
                datalabels: {
                  color: 'Black',
                  anchor: "end",
                  align: "start",
                  formatter: function (value) {
                    if ((value / 1000) >= 1) {
                      return value / 1000 + "k"
                    } else return value;
                  },
                  display: function (context) {
                    let sum = 0;
                    for (let i = 0; i < context.chart.$datalabels._datasets[0].length; i++) {
                      sum += context.chart.data.datasets[0].data[context.chart.$datalabels._datasets[0][i]._index];
                    }
                    return (context.chart.data.datasets[0].data[context.dataIndex] / sum) >= 0.1
                  },
                },
                label: this.$t('dashboard.byUser'),
                backgroundColor: [this.gradient, this.gradient2, this.gradient3, this.gradient4],
                data: this.usersTickets
              },
            ]
          },
          {
            tooltips: {
              footerFontFamily: "BloggerSans",
              bodyFontFamily: "BloggerSans",
              titleFontFamily: "BloggerSans"
            },
            legend: {
              labels: {
                fontFamily: "BloggerSans"
              }
            },
          }
        );
      })
    },
  }
}
</script>

<style scoped>

</style>